import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router";
import "./../faq/faqQuestions.css";
import { fetchFaqData } from "../../redux/Actions/policyActions";

function FAQ() {
  const navigate = useNavigate();

  const dispatch = useDispatch<any>();

  const faqData: any = useSelector<any>((state) => state?.faq);
  useEffect(() => {
    dispatch(fetchFaqData());
    document.title = 'Frequently Asked Questions | Wonderwash UK Laundry Services';
    document.getElementById('meta-title')?.setAttribute('content', 'Frequently Asked Questions | Wonderwash UK Laundry Services');
    document.getElementById('meta-description')?.setAttribute('content', "Find answers to your questions about laundry and dry cleaning services at Wonderwash UK. From pricing to turnaround time, we've got you covered.");
  }, []);

  return (
    <div>
      <div className="breadcrumpset d-none">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>FAQ'S</h2>
                <ul>
                  <li>
                    <a href="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <span> Frequently asked questions</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-faq">
        <div className="container">
          <div className="row ">
            <div className="col-md-12 d-none">
              <ol className="breadcrumb">
                <li>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/");
                    }}
                  >
                    Home
                  </a>
                </li>
                <li className="active">FAQs</li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="faq-content">
                <h2>FAQ'S</h2>
                <p>Quickly find out it we've already addressed your qurey</p>
              </div>
              <Accordion
                    defaultActiveKey={["1"]}
                    className="faq-grp"
                  >
              {faqData?.map((item: any) => {
                return (
                  
                    <Accordion.Item eventKey={JSON.stringify(item?.id)}>
                      <Accordion.Header>{item?.question}</Accordion.Header>
                      <Accordion.Body>{item?.answer}</Accordion.Body>
                    </Accordion.Item>
                 
                );
              })}
               </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
