import React from 'react'
import { easytoimg } from '../../assets/img'
import "./laundryService.css";

function LaundryService() {
  return (
    <div className="easelaundry-wrapper">
        
<div className="container-fluid">
<div className="row">
<div className="col-lg-6">
<div className="easelaundry-img-right aos-init" data-aos="zoom-in-up">
<img src={easytoimg} className="img-fluid aos-init"  alt="Ease to Made Laundry" data-aos="zoom-in-up" />
</div>
</div>
<div className="col-lg-6">
<div data-aos="zoom-in-up" className="easelaundry-content-left text-start aos-init">
<div className="section-title text-start">
<h2 className="text-start">Ease to Made Laundry</h2>
</div>
<div className="">
                        <p>Here at Wonderwash we understand how hectic and busy life can be. We are here to help you spend less time doing the laundry so you have more time doing the things you love.

                            Making things easier and simpler is our goal.</p>

                            <p>Just 4 simple steps to clean laundry

                            Book online or through app</p>
                            <ul>
                                <li>Step 1 : Choose a convenient date and time slot </li>

                                <li>Step 2 : Our driver will collect from your doorstep</li>

                                <li>Step 3 : We will do wonders with your dirty laundry</li>

                                <li>Step 4 : We will deliver your order at your chosen timeslot.</li>
                            </ul>
                           <p> Can’t get more simpler then that

                            Our team at WonderWash will wash, dry clean, iron, fold, pack your clothes neatly and deliver to you within 48 hours!!! </p>

                            <p>WonderWash provides an extensive and ever-growing list of services. Among the most popular services are shoe repairs and alterations.</p>
                       
                    </div>
</div>
</div>
</div>

</div>

    </div>
  )
}

export default LaundryService;