import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import '../aboutUs/aboutUs.css';
import { abouticon5,abouticon6,abouticon7,abouticon8,aboutimg1,aboutimg2 } from '../../assets/img';
import { fetchAboutUsData } from "../../redux/Actions/policyActions";

function AboutUs() {

    const dispatch = useDispatch<any>();

    const aboutUsData: any = useSelector<any>(
        (state) => state?.aboutUs
      );

    useEffect(() => {
        dispatch(fetchAboutUsData());
        document.title = 'About Us | Wonderwash UK Laundry Services';
        document.getElementById('meta-title')?.setAttribute('content', 'About Us | Wonderwash UK Laundry Services');
        document.getElementById('meta-description')?.setAttribute('content', "Discover the story behind Wonderwash UK and learn about our commitment to providing exceptional laundry and dry cleaning services. Get to know our team, mission, and values as we strive to exceed your expectations.");
      }, []);

    return (
        <div>
            <div className="breadcrumpset">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <div className="breadcrumpview">
                            <h2>{aboutUsData?.title_en}</h2>
                            <ul>
                            <li>
                                <a href="/"><i className="fa fa-home" aria-hidden="true"></i> </a>
                            </li>
                            <li>
                                <span>{aboutUsData?.title_en}</span>
                            </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service-content-wrapper about-content my-77 theme-clr-primory">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="policy-section">
                            <div dangerouslySetInnerHTML={{__html: aboutUsData?.details_en}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs