import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { fetchPostalCodes } from "../../redux/Actions";
import { Link, useNavigate } from "react-router-dom";
import GuestLogin from "../guestLogin";
import axios from "axios";
import {
  clearCart,
  clearCartCount,
  clearSplitPrice,
  setOrderType,
} from "./../../redux/Actions/cartCountAction";
import { setValue } from "../../utility";
import { clearUserProductCategories } from "../../redux/Actions/productCategoriesAction";
import {
  fetchAddressByPincode,
  fetchGetShopByPinCode,
  fetchSaveAddress,
} from "../../redux/Actions/checkoutPageActions";
import * as yup from "yup";
import { UKPostalCode } from "../core/regex";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
const base_url = process.env.REACT_APP_BACKEND_URL;
const client_base_url = process.env.REACT_APP_BASE_URL;

const searchPinCodeFormSchema = yup.object().shape({
  pinCode: yup
    .string()
    .required("Pin code is required")
    .matches(UKPostalCode, "Enter a valid Pin code")
    .trim()
    .max(8, "Enter a valid Pin code"),
});
const addressFormSchema = yup.object().shape({
  address: yup
    .object()
    .shape({
      label: yup.string().required("Address is required to proceed"),
      value: yup.string().required("Address is required to proceed"),
    })
    .test("address.value", "Address is required to proceed", (value: any) => {      
      if (value.value == "") {
        return false;
      } else return true;
    })
    .required("Address is required to proceed"),
});

const QuickOrderPopup: React.FC<{ close: any }> = ({ close }) => {
  const [postalCodeList, setPostalCodeList] = useState([]);
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const [guestLoginPopup, setGuestLoginPopup] = useState(false);
  const [userName, setUserName] = useState("");
  const dispatch = useDispatch<any>();
  const state: any = useSelector((state) => state);
  const navigate = useNavigate();
  const [checkWithNewPin, setCheckWithNewPin] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const [postalCodesSearch, setPostalCodesSearch] = useState<any | null>({});
  const closeModal = useRef<any>(null);

  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
    }
  }, [state]);

  const areaOptions: any = useSelector<any>(
    (state) => state?.addressByPincode?.data?.address_list
  );
  const {
    handleSubmit: handlePinCodeSubmit,
    control: pinCodeFormControl,
    setError: setPinCodeFormError,
    setValue: setPinCodeFormValue,
    reset:resetPinCodeForm,
    formState: { errors: pinCodeFormError },
  } = useForm({
    resolver: yupResolver(searchPinCodeFormSchema),
    mode: "onChange",
    defaultValues: {
      pinCode: "",
    },
  });

  const {
    handleSubmit: handleAddressSubmit,
    control: addressFormControl,
    setError: addressFormSetError,
    setValue: setAddressFormValue,
    getValues: getValuesAddressForm,
    reset: resetAddressForm,
    clearErrors,
    formState: { errors: addressFormError },
  } = useForm({
    resolver: yupResolver(addressFormSchema),
    mode: "onChange",
    defaultValues: {
      address: { value: "", label: "Select your address..." },
    },
  });
  // useEffect(() => {

  //     if (state) {

  //         const {data = {data: {}}} = state.userDetails;

  //         if (data) {
  //             const {data: {user_info = {}}} = data;
  //             const {name = ''} = user_info;
  //

  //             setUserName(name);

  //         }
  //     }
  // }, [state])

  const handleChange = (e: any) => {
    // setPostalCodeList(e.target.value);
    const { value } = e.target;
    const updateValue = value.replace(/\s/g, "");
    if (value.length > 0) {
      dispatch(fetchPostalCodes(updateValue));
    }
    setPostalCodeValue(updateValue);
  };
  // const onSearch = (searchTerm: any) => {
  //     // setPostalCodeList(searchTerm);
  //     setPostalCodeValue(searchTerm);
  //     dispatch(fetchPostalCodes(searchTerm));

  //
  // };

  const onSearch = (searchTerm: any) => {
    // setPostalCodeList(searchTerm);
    setPostalCodeValue(searchTerm);
    setValue("postalCode", searchTerm.toUpperCase());
    // dispatch(fetchPostalCodes(searchTerm));
  };

  // const handleOrder = () =>{
  //     navigate("/guestLogin");
  // }

  const handleQuickOrder = async () => {

    let userName = JSON.parse(localStorage.getItem("userDetails")!);
    const isValid = localStorage.getItem("postalCode")
    //  postalCodeList.some(
    //   (item: any) => item.postcode === postalCodeValue.toUpperCase()
    // );

    // setValue("postalCode", postalCodeValue.toUpperCase());
    // const results = await dispatch(
    //   fetchGetShopByPinCode(JSON.stringify(postalCodeValue))
    // );

    // if (!results) {
    //   window.location.href = "/areaNotCovered";
    //   return;
    // }

    if (isValid) {
      dispatch(setOrderType(1));
      setValue("orderType", 1);
      localStorage.removeItem("cartInformationData");
      dispatch(clearCartCount());
      dispatch(clearCart());
      dispatch(clearUserProductCategories());
      localStorage.removeItem('isloaded');
      // dispatch(clearSplitPrice());
      userName == null ? navigate("/guestLogin") : navigate("/checkout");
      close();
    } else {
      localStorage.removeItem("cartInformationData");
      dispatch(clearCartCount());
      dispatch(clearCart());
      dispatch(clearUserProductCategories());
      localStorage.removeItem('isloaded');
      // dispatch(clearSplitPrice());
      navigate("/areaNotCovered");
    }
  };

  const handleQuickBookClose = () => {
    setCheckWithNewPin(true)
    resetPinCodeForm()
    resetAddressForm()
  };

  // const handleQuickOrder = (event: any) => {
  //     event.preventDefault();
  //       const headers = {
  //         'Accept': 'application/json'

  //         }
  //         setPostalCodesSearch({result:null})
  //           axios.get(`${base_url}/api/get_shop_by_pincode?pincode=${ postalCodeValue }`,{
  //           headers: headers

  //     }).then(e=>{setPostalCodesSearch({result:e.data})

  // let lengthvar=e.data.Response.response_message;
  // const userinfo = JSON.parse(localStorage.getItem("userDetails")!);
  // if (lengthvar=="Sorry, we didn't cover your postal code yet! Please check for another postal code")
  // {   event.preventDefault();
  //     close();
  //     navigate("/areaNotCovered");

  //     return false
  // }
  // else if (userinfo==="")
  // {
  //     close();
  //     navigate("/guestLogin")
  // }
  // else if (userinfo !=="")
  // {
  //     close();
  //     navigate("/checkout")
  // }

  // })
  // // const userinfo = JSON.parse(localStorage.getItem("userDetails")!);
  // // if (userinfo) {
  // //     userinfo !== "" ?   navigate("/checkout") : navigate('/guestLogin')

  // // } else {
  // //     navigate("/areaNotCovered");
  // // }
  // }

  //  alert(state)
  useEffect(() => {
    if (checkWithNewPin == false) {
      setPinCodeFormValue("pinCode", "");
      setSelectedAddress(null);
      resetAddressForm();
    } else {
    }
  }, [checkWithNewPin]);
  const handleAddressChange = async (selected: any) => {
    setSelectedAddress(selected);
    setAddressFormValue("address", selected);
    if (getValuesAddressForm("address")?.value != "") {
      clearErrors();
    }
  };

  const submitAddressChange = async ()=>{
    if (getValuesAddressForm("address")?.value == "") {
      addressFormSetError("address", {
        type: "server",
        message: "Address is required to proceed",
      });
    } else {
      clearErrors();
      localStorage.setItem(
        "selectedAddressBeforeLogin",
        JSON.stringify(selectedAddress)
      );
      if (localStorage.getItem("token")) {
        // handle api
        let postalCode: any = localStorage.getItem("postalCode");
        const contact_name = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.name;
        const mobile_number = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.mobile;
        const address = selectedAddress?.value?.split(",");
        const payloadData = {
          address_type: "pickup",
          address_details: `{"contact_name":"${contact_name}","mobile_number":"${mobile_number}","line_1":"${address[0].trim()}","line_2":"${address[1].trim()}","line_3":"${address[2].trim()}","line_4":"${address[3].trim()}","locality":"${address[4].trim()}","city":"${address[5].trim()}","county":"${address[6].trim()}","label":"Home","pincode":"${JSON.parse(
            postalCode
          )}"}`,
        };
        const uniqueAddress = await dispatch(fetchSaveAddress(payloadData));
        if (uniqueAddress.status || uniqueAddress.message == "Address already exists") {
          handleQuickOrder()
          closeModal.current.click()
        } else {
          addressFormSetError("address", {
            type: "server",
            message: "Unable to set address try again later",
          });
        }
      } else {
        handleQuickOrder()
        closeModal.current.click()
      }
    }
  }

  const submitPostalForm = async (data: any) => {
    localStorage.setItem("postalCode", JSON.stringify(data.pinCode));
    const shop = await dispatch(
      fetchGetShopByPinCode(JSON.stringify(data.pinCode))
    );
    if (shop) {
      const result = await dispatch(fetchAddressByPincode());
      if (result.status == 200 && result.data.data?.address_list.length > 0) {
        setCheckWithNewPin(false);
      } else {
        setPinCodeFormError("pinCode", {
          type: "server",
          message: "Area not covered",
        });
      }
    } else {
      setPinCodeFormError("pinCode", {
        type: "server",
        message: "Area not covered",
      });
    }
  };

  useEffect(()=>{
    setSelectedAddress(selectedAddress)
  },[selectedAddress])
  return (
    <div>
      <div className="modal-header">
        <h3>Please enter your postal code</h3>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          ref={closeModal}
          onClick={handleQuickBookClose}
        ></button>
      </div>
      <div className="modal-body">
        <div className="postcodeform postcodeform-modal">
          <form onSubmit={handlePinCodeSubmit(submitPostalForm)}>
            <div className={`${checkWithNewPin == false ? "d-none" : ""}`}>
              <Controller
                name="pinCode"
                control={pinCodeFormControl}
                render={({ field }) => (
                  <input
                    type="text"
                    id="postcode-input"
                    {...field}
                    placeholder="Enter Zipcode"
                    className={`ui-autocomplete-input ${
                      pinCodeFormError.pinCode ? "invalid" : ""
                    }`}
                  />
                )}
              />
              <small className="text-danger postvalidation">
                {pinCodeFormError.pinCode?.message}
              </small>

              <input
                type="submit"
                name=""
                id=""
                // data-bs-toggle="modal"
                value="Search"
                // onClick={handleQuickOrder}
              />
              <input
                type="hidden"
                name="order_type"
                id="order_type"
                value="1"
              />
              <input
                type="hidden"
                name="_token"
                value="3kG3W86Tk4OJE3wsaVIvIiCGDFbW3jWfdIIGaft3"
              />
            </div>
          </form>
          <form>
            <div
              className={`adrsselectform ${
                checkWithNewPin == true ? "d-none" : ""
              }`}
            >
              <small className="text-danger postvalidation">
                {addressFormError.address?.message}
              </small>
              <div className="adrsselectdd">
                <Controller
                  name="address"
                  control={addressFormControl}
                  render={({ field }) => (
                    <Select
                      placeholder="Select your address"
                      {...field}
                      value={selectedAddress}
                      className={`${addressFormError.address ? "invalid" : ""}`}
                      onChange={handleAddressChange}
                      onFocus={() => {
                        if (getValuesAddressForm("address").value == "") {
                          addressFormSetError("address", {
                            type: "server",
                            message: "Address is required to proceed",
                          });
                        } else {
                          clearErrors();
                        }
                      }}
                      options={areaOptions}
                    />
                  )}
                />
                <input
                type="button"
                value="Continue"
                onClick={submitAddressChange}
              />
              </div>
              <div className="adrsselect-or">OR</div>
              <div className="adrsselect-link">
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    setCheckWithNewPin(true);
                  }}
                >
                  Check with another Zipcode
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* {guestLoginPopup && <GuestLogin />} */}
    </div>
  );
};

export default QuickOrderPopup;
