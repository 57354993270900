import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';

const DeleteAddressPopup: React.FC<{ close: any, id: Number }> = ({ close, id }) => {
    // const [deleteAddress, deleteMyAddress] = useState()

    const notify = (message: string) => toast(message);

    const handleQuickBookClose = () => {
        close();
    }

    const base_url = process.env.REACT_APP_BACKEND_URL;

    const token = localStorage.getItem("token");

    function handleAddressDelete() {
        const headers = {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }

        axios.post(`${base_url}/api/delete_address`, { address_id: id }, {
            headers: headers
        }).then(e => {
            if (e.data.Response.response_code == "1" && e.data.Response.response_message == "address deleted successfully") {
                notify(e.data.Response.response_message)
                window.location.reload();
                return true
            }
            else {
                notify(e.data.Response.response_message)
                window.location.reload();
                // setGenError(e.data.Response.response_message);
                return false
            }
        })
    }

    return (

        <div>
            <div className="custom-modal-class modal in d-block" id="app_download_success_popup">
                <div className="vertical-alignment-helper">
                    <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">

                            <div className="modal-body">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleQuickBookClose}></button>
                                <h3 className="text-center">Confirm Delete?</h3>

                                {/* <h3>Thank you for showing interest in our app. You will get the link via SMS soon.</h3>
                                <input type="submit" value="Close" data-bs-dismiss="modal" onClick={handleThankAppDownloadPopupClose} /> */}
                                <form id="quick-order-form" >
                                    <a className="btn btn-success me-1" data-bs-dismiss="modal" onClick={handleQuickBookClose} >No</a>
                                    <a className="btn btn-danger ms-1" onClick={handleAddressDelete}>Yes</a>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="modal-dialog" id="quickordermodal">
                <div className="vertical-alignment-helper">
                    <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleQuickBookClose}></button>
                                <h3 className="text-center">Confirm Delete?</h3>
                                <form id="quick-order-form" >
                                    <a className="btn dark-white p-x-md" data-bs-dismiss="modal" onClick={handleQuickBookClose} >No</a>
                                    <a className="btn danger p-x-md" onClick={handleAddressDelete}>Yes</a>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div >
    )
}

export default DeleteAddressPopup;